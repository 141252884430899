<template>
    <section class="page-main__section promo-section">
        <div class="page-wrapper promo-wrapper">
            <h2 class="promo-section__text">
                Innovative <span class="animated-typing-1 highlight"></span> <span class="second-stroke"> that
                drive for <br> <span class="animated-typing-2 highlight"></span></span>
            </h2>
            <div class="bg-1 bg-img"><img src="/assets/img/bg/quad.svg"></div>
            <div class="bg-2 bg-img"><img src="/assets/img/bg/rect.svg"></div>
            <div class="bg-3 bg-img"><img src="/assets/img/bg/big.svg"></div>
        </div>
    </section>
</template>

<script>
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
gsap.registerPlugin(TextPlugin)
export default {

}
</script>
<style lang="scss">
    .animated-typing-1 {
      display:block;

      @media(min-width:theme('screens.905')) {
        @apply inline
      }
    }
</style>