<script>
import arrowBtn from "./../../buttons/btn-control.vue"

export default {
  data() {
    return {
      items: ['Landing Pages', 'Website of Company', 'Showcase website (catalog)', 'Online store', 'CRM, Services and Portals']
    }
  },
  components: {
    arrowBtn
  }
}

</script>

<template>
  <section class="page-main__section subpage">
    <div class="page-wrapper subpage-wrapper">
      <div class="subpage__header">
        <h2 class="subpage__title highlight">​​Development</h2>
        <ul class="subpage__list subpage-list">
          <li v-for="item of items" key="item" class="subpage-list__item"><p class="subpage-list__title">{{ item }}</p></li>
        </ul>
      </div>
      <div class="subpage__footer">
        <p class="subpage__description">Designing, testing, and maintaining high-tech web systems for any business needs. We have all resources to implement the most complex business solutions of the client.</p>
        <router-link class="subpage-btn" to="/"><arrowBtn class="control-btn arrow-left subpage-btn"/></router-link>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
