<template>
  <header class="page-header">
    <div class="page-header__wrapper">
      <Logo @go-to-main="clickToSlide(0)"/>
      <div class="page-header__btn-block">
        <button type="button" class="page-header__menu-btn" @click="openMenu">Menu</button>
      </div>
      <div class="page-header__right-block">
        <nav class="page-header__navigation">
          <div class="page-header__menu main-menu">
            <ul class="main-menu__list">
              <li class="main-menu__item">
                <router-link to="/"><button class="main-menu__link" @click="clickToSlide(2)">Services</button></router-link>
              </li>
              <li class="main-menu__item slider-section slider-section">
                <router-link to="/"><button class="main-menu__link" @click="clickToSlide(3)">Cases</button></router-link>
              </li>
              <li class="main-menu__item">
                <router-link to="/"><button class="main-menu__link" @click="clickToSlide(3)">About</button></router-link>
              </li>
              <li class="main-menu__item">
                <router-link to="/"><button class="main-menu__link" @click="clickToSlide(5)">Contact</button></router-link>
              </li>
            </ul>
          </div>
        </nav>
        <div class="page-header__contacts">
          <a href="mailto:hello@bl47.com" class="page-header__mail highlight">hello@bl47.com</a>
          <!--          <a href="tel:+2063524876" class="page-header__phone">206 352 4876</a>-->
        </div>
      </div>
    </div>
  </header>
  <main class="page-main">
    <div class="content">
      <swiper
        class="main-content"
        :direction="'vertical'"
        :speed="1500"
        :slidesPerView="1"
        :slidesPerGroup="1"
        :mousewheel="true"
        :modules="modules"
        :touchRatio="0.2"
        ref="main-swiper"
        @swiper="onSwiper"
        @slideChange="checkActiveSlideColor(this.swiper.activeIndex)"
        :effect="'creative'"
        :creativeEffect="{
                prev: {
                  shadow: true,
                  translate: [0, '-50%', -1]
                },
                next: {
                  translate: [0, '100%', 0]
                }
              }"
      >
        <swiper-slide>
          <firstSection @to-slide="clickToSlide(1)" @contact="clickToSlide(5)"/>
        </swiper-slide>
        <swiper-slide>
          <promoSection/>
        </swiper-slide>
        <swiper-slide>
          <textSlider/>
        </swiper-slide>
        <!-- <swiper-slide>
          <slideSection/>
        </swiper-slide> -->
        <swiper-slide>
          <about @contact="clickToSlide(5)"/>
        </swiper-slide>
        <swiper-slide>
          <Contacts/>
        </swiper-slide>
      </swiper>
    </div>
  </main>
</template>

<script>
import gsap from "gsap";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { EffectCreative, Mousewheel } from "swiper/modules";
import firstSection from "./../sections/main-section.vue";
import promoSection from "./../sections/promo.vue";
import textSlider from "./../sections/textslider.vue";
import slideSection from "./../sections/slidersection.vue";
import about from "./../sections/about.vue";
import Logo from "./../header/logo.vue";
import Contacts from "./../sections/contacts.vue";

export default {
  methods: {

    animateAboutTyping(index) {
      if(index === 3 && this.aboutAnimate) {
        gsap.fromTo('.animated-type', {
        text: 'B'
      }, {
        text: 'BlackLabel 47',
        ease: "power1.out",
        delay: .5,
        duration: 2,
        onComplete: this.aboutAnimate = false
      })
      }
    },

    animatePromoTyping(index) {
      if(index === 1 && this.promoAnimate) {
        gsap.fromTo('.animated-typing-1', {
        text: 'd'
      }, {
        text: 'designs',
        duration: 2,
        ease: "power1.out",
        delay: .5,
      })

      gsap.fromTo('.animated-typing-2', {
        text: 'r' 
      }, {
        text: 'results',
        duration: 2,
        ease: "power1.out",
        delay: .5,
        onComplete: this.promoAnimate = false,
      },'-=2.5')
      }
    },

    checkActiveSlideColor(index) {

      //get current slide bg color, if we need this method
      // let mainSlides = document.querySelectorAll(".page-main__section");
      // let currentSlideBackgroundColor = getComputedStyle(mainSlides[index]).backgroundColor;
      // let color = currentSlideBackgroundColor
      //   .split("rgb")[1]
      //   .split(" ")
      //   .join("");
      const logo = document.querySelector(".page-header__logo");
      const menuItems = document.querySelectorAll(".main-menu__link");
      const menuBtn = document.querySelector(".page-header__menu-btn");
      // const chText = document.querySelector('.ch__text');

        // start of animation
        this.animatePromoTyping(index)
        this.animateAboutTyping(index)

      //change text color on slide change
      if (index === 0 || index === 2 || index === 4) {
        [logo, menuBtn].forEach(item => {
          item.classList.remove('darken');
        })
        menuItems.forEach(item => {
            item.classList.remove('darken')
          });

        if (!menuBtn.classList.contains("open")) {
           [logo, menuBtn].forEach(item => {
          item.classList.remove('darken');
        })
          menuItems.forEach(item => {
            item.classList.remove('darken')
          });
        }
      }

      if (index === 1 || index === 3 ) {
         [logo, menuBtn].forEach(item => {
          item.classList.add('darken');
        })

        if (!menuBtn.classList.contains("open")) {
          menuItems.forEach(item => {
            item.classList.add('darken')
          });
        }
      }
    },
    clickToSlide(index, duration = 1500) {
      this.swiper.slideTo(index);
    },
    //init swiper
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    //add menu items handler
    menuItemsHandler() {
      const headerMenu = document.querySelector(".page-header__right-block");
      const menuBtn = document.querySelector(".page-header__menu-btn");
      menuBtn.classList.remove("open");
      gsap.fromTo(
        headerMenu,
        {
          y: `100vh`,
          autoAlpha: 1
        },
        {
          y: 0,
          delay: 0.2,
          duration: 0.5,
          ease: "power2.out",
          autoAlpha: 0
        }
      );
    },

    openMenu() {
      const menuBtn = document.querySelector(".page-header__menu-btn");
      const headerMenu = document.querySelector(".page-header__right-block");
      const menuItems = document.querySelectorAll(".main-menu__link");
      const logo = document.querySelector(".page-header__logo");
      const chText = document.querySelector('.ch__text');

      menuBtn.classList.toggle("open");

      if (menuBtn.classList.contains("open")) {
        menuBtn.classList.add('mobile-menu-open');
        logo.classList.add('mobile-menu-open');
        // chText.classList.add('mobile-menu-open');
        menuItems.forEach((item) => {
            item.classList.add('mobile-menu-open');
          item.addEventListener("click", this.menuItemsHandler);
        }
        );
        gsap.fromTo(
          headerMenu,
          {
            y: 0,
            autoAlpha: 0
          },
          {
            y: `100vh`,
            delay: 0.2,
            duration: 0.5,
            ease: "power2.out",
            autoAlpha: 1
          }
        );
      } else {
        menuBtn.classList.remove('mobile-menu-open');
        logo.classList.remove('mobile-menu-open');
        // chText.classList.remove('mobile-menu-open');

        menuItems.forEach(item =>
            {
              item.classList.remove('mobile-menu-open')
              item.removeEventListener("click", this.menuItemsHandler)
            });

        gsap.fromTo(
          headerMenu,
          {
            y: `100vh`,
            autoAlpha: 1
          },
          {
            y: 0,
            delay: 0.2,
            duration: 0.5,
            ease: "power2.out",
            autoAlpha: 0
          }
        );
      }
    }
  },

  components: {
    Logo,
    firstSection,
    promoSection,
    textSlider,
    slideSection,
    about,
    SwiperSlide,
    Swiper,
    Contacts
  },

  data() {
    return {
      step: 1,
      swiper: null,
      textColor: {
        color: "#000000"
      },
      promoAnimate: true,
      aboutAnimate: true,
    };
  },

  setup() {
    return {
      modules: [Mousewheel, EffectCreative]
    };
  }
};
</script>
