<script>
import arrowBtn from "./../../buttons/btn-control.vue"

export default {
  data() {
    return {
      items: ['Expertise', 'Сommunication strategies', 'Communities in social networks', 'Communication design']
    }
  },
  components: {
    arrowBtn
  }
}

</script>

<template>
  <section class="page-main__section subpage">
    <div class="page-wrapper subpage-wrapper">
      <div class="subpage__header">
        <h2 class="subpage__title highlight">Internet Marketing</h2>
        <ul class="subpage__list subpage-list subpage-list--backend">
          <li v-for="item of items" key="item" class="subpage-list__item"><p class="subpage-list__title">{{ item }}</p></li>
        </ul>
      </div>
      <div class="subpage__footer">
        <p class="subpage__description">We set communication goals clearly and that marketing really needs, we segment the audience, build an effective metrics tree, design a system of tools and channels, carefully consider the concept, step by step we plan the ways to achieve goals and get measurable results.</p>
        <router-link class="subpage-btn" to="/"><arrowBtn class="control-btn arrow-left subpage-btn"/></router-link>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>