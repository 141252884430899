<script>
import arrowBtn from "./../../buttons/btn-control.vue"

export default {
  data() {
	return {
	  items: ['UI/UX Design', 'Web Design', 'Apps Design', 'Visual Identity & Branding', 'Expertise', 'Style Guide', 'Transfer to Development']
	}
  },
  components: {
	arrowBtn
  }
}

</script>

<template>
<section class="page-main__section subpage">
  <div class="page-wrapper subpage-wrapper">
	<div class="subpage__header">
	  <h2 class="subpage__title highlight">Design</h2>
	  <ul class="subpage__list subpage-list">
		<li v-for="item of items" key="item" class="subpage-list__item"><p class="subpage-list__title">{{ item }}</p></li>
	  </ul>
	</div>
	<div class="subpage__footer">
	  <p class="subpage__description">We create a design system in projects - a visual language based on a rule and typical elements that allows developers to create new products quickly and easily, while maintaining the visual integrity of the project.</p>
	  <router-link class="subpage-btn" to="/"><arrowBtn class="control-btn arrow-left subpage-btn"/></router-link>
	</div>
  </div>
</section>
</template>

<style scoped>

</style>
