<script setup>
import {useRouter, useRoute} from "vue-router";
import {createRouter, createWebHistory} from 'vue-router'

const router = useRouter();
const route = useRoute();
</script>

<template>
<section class="page-main__section contacts">
  <div class="page-wrapper">
    <div class="contacts-wrapper__inner">
      <p class="contacts__text highlight">Are you ready to have your own project created?</p>
      <a href="mailto:hello@bl47.com" class="contacts__mail">hello@bl47.com</a>
      <ul class="social-list">
        <li class="social-list__item social-list__item-telegram"><a href="" class="social-list__link"><svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.84075 9.7381C8.8314 6.67268 13.5043 4.69137 15.8594 3.71941C22.5136 0.953053 23.8968 0.467071 24.794 0.429688C24.9809 0.429688 25.4295 0.467071 25.7286 0.69137C25.9529 0.878286 26.0277 1.13997 26.065 1.32688C26.1024 1.5138 26.1398 1.92502 26.1024 2.26146C25.7286 6.03716 24.1959 15.2708 23.3735 19.4951C23.037 21.2895 22.3641 21.8876 21.7286 21.9624C20.3454 22.0745 19.2613 21.0278 17.9155 20.168C15.8221 18.7848 14.6258 17.925 12.5697 16.5792C10.2146 15.0091 11.7473 14.1493 13.0931 12.7661C13.4295 12.3923 19.5978 6.82221 19.7099 6.29885C19.7099 6.22408 19.7473 5.99978 19.5978 5.88763C19.4482 5.77548 19.2613 5.81287 19.1118 5.85025C18.8875 5.88763 15.523 8.13062 8.98093 12.5418C8.00897 13.2147 7.14916 13.5138 6.36411 13.5138C5.5043 13.5138 3.85944 13.0278 2.62579 12.6166C1.13047 12.1306 -0.0657959 11.8689 0.0463536 11.0465C0.12112 10.6353 0.719251 10.1867 1.84075 9.7381Z" fill="#212322"/>
        </svg>
        </a></li>
        <li class="social-list__item social-list__item-twitter"><a href="" class="social-list__link"><svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.60962 23.6602C20.2536 23.6602 26.0736 14.8402 26.0736 7.19624C26.0736 6.94424 26.0736 6.69224 26.0616 6.45224C27.1896 5.63624 28.1736 4.61624 28.9536 3.45224C27.9216 3.90824 26.8056 4.22023 25.6296 4.36423C26.8296 3.64423 27.7416 2.51623 28.1736 1.16023C27.0576 1.82023 25.8216 2.30023 24.5016 2.56423C23.4456 1.43623 21.9456 0.740234 20.2776 0.740234C17.0856 0.740234 14.4936 3.33223 14.4936 6.52423C14.4936 6.98023 14.5416 7.42423 14.6496 7.84423C9.83762 7.60423 5.57763 5.30024 2.72163 1.79623C2.22963 2.64823 1.94163 3.64423 1.94163 4.70023C1.94163 6.70424 2.96163 8.48024 4.52163 9.51224C3.57363 9.48824 2.68562 9.22424 1.90562 8.79224C1.90562 8.81624 1.90562 8.84023 1.90562 8.86423C1.90562 11.6722 3.89762 14.0002 6.54962 14.5402C6.06962 14.6722 5.55363 14.7442 5.02562 14.7442C4.65363 14.7442 4.29363 14.7082 3.93363 14.6362C4.66563 16.9402 6.80163 18.6082 9.33363 18.6562C7.35363 20.2042 4.85763 21.1282 2.14562 21.1282C1.67762 21.1282 1.22162 21.1042 0.765625 21.0442C3.29763 22.7002 6.34562 23.6602 9.60962 23.6602Z" fill="#212322"/>
        </svg>
        </a></li>
      </ul>
    </div>

    <div class="contacts-text__wrapper">
      <p class="contacts__description">We are strongly assured that the best websites and applications are those ones based on simplicity and clarity as key elements in making websites usable, and we strive to achieve website usability in each designed project we work on</p>
      <a href="./privacy-policy.html" class="privacy-policy">Privacy policy</a>
    </div>
  </div>
</section>
</template>

<style scoped>

</style>