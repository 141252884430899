<template>
    <button type="button">{{ title }}</button>
</template>
<script>
export default {
    props: {
        title: String,
    }
}
</script>
