<script>
import arrowBtn from "./../../buttons/btn-control.vue"

export default {
  data() {
    return {
      items: ['Elimination of technical errors', 'Refinement of functionality and development of new functions', 'Website integration with external services', 'Content maintenance and design support', 'Resource health monitoring']
    }
  },
  components: {
    arrowBtn
  }
}

</script>

<template>
  <section class="page-main__section subpage">
    <div class="page-wrapper subpage-wrapper">
      <div class="subpage__header">
        <h2 class="subpage__title highlight">Continual Support</h2>
        <ul class="subpage__list subpage-list">
          <li v-for="item of items" key="item" class="subpage-list__item"><p class="subpage-list__title">{{ item }}</p></li>
        </ul>
      </div>
      <div class="subpage__footer">
        <p class="subpage__description">Maintaining the site and servers, adapting to new versions of the web environment, updating to maintain security and bringing it into line with new legal requirements.</p>
        <router-link class="subpage-btn" to="/"><arrowBtn class="control-btn arrow-left subpage-btn"/></router-link>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>