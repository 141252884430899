<template>
  <section class="page-main__section services">
    <router-view v-slot="{ Component}">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </section>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router";
import {createRouter, createWebHistory} from 'vue-router'

const router = useRouter();
const route = useRoute();


</script>
<script>
import gsap from 'gsap'


export default {

}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>