import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import services from "./components/sections/services-inner.vue";
import design from "./components/sections/subpages/design.vue";
import development from "./components/sections/subpages/development.vue";
import support from "./components/sections/subpages/support.vue";
import marketing from "./components/sections/subpages/marketing.vue";
import "./js/animate-bg";

const router= createRouter({
    routes: [
        {
            path: '/',
            component: services,
            name: 'services'
        },
        {
            path: '/design',
            component: design,
            name: "design"
        },
        {
            path: '/development',
            component: development,
            name: "development"
        },
        {
            path: '/support',
            component: support,
            name: "support"
        },
        {
        path: '/marketing',
        component: marketing,
        name: 'marketing'
    }],
    history: createWebHistory()
})
const app = createApp(App)
app.use(router)
app.mount('#page-body')
