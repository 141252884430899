<template>
  <section class="page-main__section slider-section" id="services">
    <div class="slider-section__content">
      <swiper class="slider-section-slider"
              :modules="modules"
              :speed="1500"
              :slidesPerGroup="1"
              :slidePerView="1"
              :touchRatio="0.2"
              :navigation="{
                nextEl: '.btn-next',
                prevEl: '.btn-prev',
                disabledClass: 'disabled'
            }"
              :grabCursor="false"
              :effect="'creative'"
              :creativeEffect="{
                prev: {
                  shadow: true,
                  translate: ['-20%', 0, -10],

                },
                next: {
                  translate: ['100%', 0, 0],
                }
              }"

      >
        <swiper-slide v-for="slide of slides" :key="slide.id" class="slider-section-slider__item slider-item">
          <div class="slider-item__bg">
            <img :src="slide.src" alt="">
          </div>
          <div class="slider-item__content">
            <div class="slider-item__description">
              <h3 class="slider-item__title">
                <span>{{ slide.title }}</span>
                <span class="slider-item__date">{{ slide.data }}</span>
              </h3>
            </div>
          </div>
        </swiper-slide>
        <div class="slider-control-panel page-wrapper">
          <controlBtn class="slider-control__btn btn-prev control-btn arrow-left"/>
          <controlBtn class="slider-control__btn btn-next control-btn arrow-right"/>
        </div>
      </swiper>
    </div>
  </section>
</template>


<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cube';
import {Navigation, EffectCreative} from "swiper/modules";
import controlBtn from './../buttons/btn-control.vue';


export default {

  data() {
    return {
      slides: [
        {
          title: "Online furniture store",
          src: "./assets/img/cases/furniture.jpg",
          data: '2021'
        },
        {
          title: "Online furniture store",
          src: "./assets/img/cases/furniture.jpg",
          data: '2021'
        },
        {
          title: "Online furniture store",
          src: "./assets/img/cases/furniture.jpg",
          data: '2021'
        },
      ],
    }
  },

  components: {
    Swiper,
    SwiperSlide,
    controlBtn
  },
  setup() {
    return {
      modules: [Navigation, EffectCreative]
    }
  },

}

</script>

<style scoped>

</style>