<template>
    <button type="button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M12 24L0 12L1.575 10.425L10.875 19.725V0H13.125V19.725L22.425 10.425L24 12L12 24Z" fill="currentColor"/>
        </svg>
    </button>
</template>

<script>
export default  {
emits: ['countStep']
}
</script>