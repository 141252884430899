<template>
    <section class="page-main__section hello-section">
      <canvas id="canvas"></canvas>
        <div class="page-wrapper hello-section__wrapper">
            <div class="hello-section__up-block">
                <h1 class="hello-section__title highlight">Design and Development Agency</h1>
                <p class="hello-section__description">Where creativity meets technology</p>
            </div>
            <div class="hello-section__bottom-block">
                <div class="hello-section__inner-wrapper">
                    <primaryBtn class="primary primary-lg" title="Contact us" @click="$emit('contact')"/>
                    <arrowBtn class="control-btn arrow-bottom" @click="$emit('toSlide')"/>
                </div>
            </div>

        </div>
    </section>
</template>
<script setup>
import primaryBtn from "./../buttons/primary.vue"
import arrowBtn from "./../buttons/btn-control.vue"


</script>

<script>
export default  {
emits: ['toSlide', 'contact']
}
</script>