<script setup>
import primaryBtn from "./../buttons/primary.vue"
</script>

<script>

import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
gsap.registerPlugin(TextPlugin)

export default {


}
</script>

<template>
  <section class="page-main__section about">
    <div class="page-wrapper about-wrapper">
      <div class="about-content">
        <h2 class="about__title highlight animated-type"></h2>
        <p class="about-text">Our main activity is the planning and development of e-commerce projects. We have been working since 2006 and have gained the huge experience designing big websites and online services platforms, which are still successfully operating and have been attended by more than 10 thousand people per day. The rave reviews from our clients have made a lot of other entrepreneurs and companies cooperate with us</p>
        <primaryBtn class="primary primary-lg" title="Contact us" @click="$emit('contact')"/>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>