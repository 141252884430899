<template>
  <div class="page-header__left-block">
    <button class="page-header__logo" @click="$emit('goToMain')">
      BlackLabel
      <span class="highlight">47</span>
    </button>
<!--    <div class="ch-container">
      <img src="/assets/img/ch.png" class="ch" wdith="17" height="20">
      <p class="ch__text">Text</p>
    </div>-->
  </div>
</template>


<script>
import { ref } from "vue";

export default {};
</script>
